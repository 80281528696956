<template>
  <div class="home">
    <home-view />
  </div>
</template>

<script setup>
import HomeView from './views/HomeView.vue'
import '@/assets/icon/style.css'
import { onMounted } from 'vue'

onMounted(() => {
  document.title = 'Eka Restu D. Putra | Expert in Photoshop and Blender 3D'
})
</script>

<style lang="scss">
.home {
  @include sizes;
}
</style>
