<template>
  <div class="container">
    <div class="row" id="contact">
      <div class="col-lg-5">
        <div class="header-contact">
          <h2 class="title">Get in Touch</h2>
          <p class="description">Let's connect! Drop me a line and say hello.</p>
        </div>
        <div class="form-wrap">
          <div class="form-row">
            <input type="text" v-model="formData.userName" name="user_name" required>
            <label>What's your name?</label>
            <span class="error" v-for="error in errors" :key="error.index">
              <span v-if="error.property === 'userName'">{{ error.message }}</span>
            </span>
          </div>
          <!-- Email -->
          <div class="form-row">
            <input type="text" v-model="formData.userEmail" name="user_email" required>
            <label>And your email please</label>
            <span class="error" v-for="error in errors" :key="error.index">
                <span v-if="error.property === 'userEmail'">{{ error.message }}</span>
              </span>
          </div>
          <!-- Text area -->
          <div class="form-row">
            <textarea v-model="formData.message" name="message" required></textarea>
            <label>What do you want to say?</label>
            <span class="error" v-for="error in errors" :key="error.index">
              <span v-if="error.property === 'message'">{{ error.message }}</span>
            </span>
          </div>
        </div>
        <div class="submit-area">
          <a class="btn btn-cta" :class="{ 'btn-loading': button.loading }" type="submit" value="Send" @click="sendEmail">
            <span v-show="button.normal">Let's chat <span class="icon-sent" /></span>
            <div v-show="button.loading" class="lds-ring"><div></div><div></div><div></div><div></div></div>
            <span v-show="button.success" class="success">Thank you <span class="icon-success" /></span>
            <span v-show="button.fail" class="fail">Something wrong <span class="icon-fail" /></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import emailjs from 'emailjs-com'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'

const button = reactive({
  normal: true,
  loading: false,
  success: false,
  fail: false
})

const formData = reactive({
  userName: '',
  userEmail: '',
  message: ''
})

const errors = reactive({})

const rules = {
  userName: {
    required: helpers.withMessage('Name cannot be empty', required)
  },
  userEmail: {
    required: helpers.withMessage('Email cannot be empty', required),
    email: helpers.withMessage('Please enter valid email format', email)
  },
  message: {
    required: helpers.withMessage('Message cannot be empty', required)
  }
}

const v$ = useVuelidate(rules, formData)

const sendEmail = async () => {
  button.loading = true
  button.normal = false
  button.fail = false
  button.success = false

  for (const field in errors) {
    errors[field] = {}
  }

  const result = await v$.value.$validate()
  if (result) {
    // EmailJS start
    const templateParams = {
      from_name: formData.userName,
      reply_to: formData.userEmail,
      message: formData.message
    }

    emailjs.send(process.env.VUE_APP_SRV_ID, process.env.VUE_APP_TMP_ID, templateParams, process.env.VUE_APP_PB_KEY)
      .then((response) => {
        button.loading = false
        button.success = true
        formData.userName = ''
        formData.userEmail = ''
        formData.message = ''
      })
      .catch((error) => {
        button.loading = false
        button.fail = true
        // Handle the error here
        console.error('Email sending failed:', error)
      })
    // EmailJS END
  } else {
    setTimeout(() => {
      button.loading = false
      button.fail = true
      const validationErrors = {}
      for (const field in v$.value.$errors) {
        const error = v$.value.$errors[field]
        validationErrors[field] = {
          property: error.$property,
          message: error.$message
        }
      }
      Object.assign(errors, validationErrors)
    }, 3000)
  }
}

</script>

<style lang="scss" scoped>
#contact {
  @include flex($jst-c: center, $alg-i: center);

  .header-contact {
    text-align: center;
    margin-bottom: rem(50px);

    .title {
      font-weight: 600;
      font-size: rem(25px);
    }

    .description {
      color: var(--font-color-sec);
      font-size: rem(16px);
    }
  }

  .form-wrap {
    @include flex(column);
    @include area(100%);

    .form-row {
      @include flex(column);
    }

  }
  .submit-area {
    @include flex();
    margin-top: rem(15px);
  }
}

.success {
  color: $success;
}
.fail {
  color: $danger;
}
</style>
