<template>
  <div class="icon-scroll"></div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.icon-scroll {
  width: 20px;
  height: 30px;
  border: 2px solid var(--font-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  box-shadow: 3px 3px 5px -5px #000;
}

.icon-scroll::after {
  content: "";
  @include area(5px);
  border-radius: 100%;
  background-color: var(--font-color);
  position: absolute;
  display: block;
  animation: scroll ease 1s infinite;
}
@keyframes scroll {
  from {
    top: 5px;
  }
  to {
    top: 18px;
  }
  100% {
    opacity: 0;
  }
}
</style>
