<template>
  <div id="nav">
    <div class="container wrapper">
      <div class="logo">
        <a href="/">
          <span class="icon-logo" />
        </a>
      </div>
      <div class="social-media">
        <ul>
          <li><a href="https://www.youtube.com/@erdpme" title="Youtube" target="_blank"><span class="icon-youtube" /></a></li>
          <li><a href="https://www.instagram.com/erdpme/" title="Instagram" target="_blank"><span class="icon-instagram" /></a></li>
          <li><a href="https://www.tiktok.com/@erdpme" title="Tiktok" target="_blank"><span class="icon-tiktok" /></a></li>
          <li><a href="https://www.linkedin.com/in/erdpme/" title="Linkedin" target="_blank"><span class="icon-linkedin" /></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
#nav {
  @include area(100%, auto);
  left: 0;
  top: 0;
  position: absolute;

  .logo {
    font-size: rem(30px);

    img {
      @include area(rem(100px), auto);
    }
  }

  .wrapper {
    @include flex($jst-c: space-between, $alg-i: center);
    @include padding(rem(20px) 0);
    color: var(--font-color-sec);
    border-bottom: var(--divider-color) solid 1px;

    a {
      text-decoration: none;
      color: var(--font-color-sec);
      transition: $transition;

      &:hover {
        color: var(--font-color);
      }
    }

    .social-media {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
      @include flex($jst-c: center, $alg-i: center);
    }
  }
}
</style>
