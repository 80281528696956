<template>
  <div class="container">
    <div class="footer-wrap">
      <div class="copyright">© <a href="/">Eka Restu D. Putra</a> - {{currentYear}} All rights reserved.</div>
      <div class="social-media">
        <ul>
          <li><a href="https://www.youtube.com/@erdpme" title="Youtube" target="_blank"><span class="icon-youtube" /></a></li>
          <li><a href="https://www.instagram.com/erdpme/" title="Instagram" target="_blank"><span class="icon-instagram" /></a></li>
          <li><a href="https://www.tiktok.com/@erdpme" title="Tiktok" target="_blank"><span class="icon-tiktok" /></a></li>
          <li><a href="https://www.linkedin.com/in/erdpme/" title="Linkedin" target="_blank"><span class="icon-linkedin" /></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const currentDate = new Date()
const currentYear = ref(currentDate.getFullYear())
</script>

<style lang="scss" scoped>
.footer-wrap {
  @include flex($jst-c: space-between, $alg-i: center);
  border-top: 1px solid var(--divider-color);
  padding: rem(40px) 0;
  margin-top: rem(100px);

  @include media-breakpoint-down(sm) {
    @include flex(column, $jst-c: center, $alg-i: center);
    gap: rem(30px);
  }

  a {
    text-decoration: none;
    color: var(--font-color-sec);
  }

  .copyright {
    color: var(--font-color-sec);
    font-size: rem(12px);
    letter-spacing: rem(.5px);
  }
}
</style>
