<template>
  <div class="store">
    <div class="container">
      <div class="text">
        <h2 class="title">I'm Selling Mockup</h2>
        <p class="description">I was also selling a ready Mockup template. Let's check! might you are interested</p>
      </div>
      <div class="store-list">
        <!-- Erdp Store -->
        <a href="https://store.erdp.me/" class="store-item" target="_blank">
          <div class="logo">
            <img :src="erdpStoreLogo" alt="Erdp store logo">
          </div>
          <div class="store-title">Erdp Store</div>
        </a>
        <!-- Envato element -->
        <a href="https://elements.envato.com/user/erdpme" class="store-item" target="_blank">
          <div class="logo">
            <img src="https://res.cloudinary.com/erdp-me/image/upload/v1659710330/store/envato_dspr6o.png" alt="Envato Logo">
          </div>
          <div class="store-title">Envato Element</div>
        </a>
        <!-- Creative Market -->
        <a href="https://creativemarket.com/erdpme" class="store-item" target="_blank">
          <div class="logo">
            <img :src="cmLogo" alt="Creative Market Logo">
          </div>
          <div class="store-title">Creative Market</div>
        </a>
        <!-- Gumroad -->
        <a href="https://shop.erdp.me/" class="store-item" target="_blank">
          <div class="logo">
            <img src="https://res.cloudinary.com/erdp-me/image/upload/v1659710331/store/gumroad_x6aayw.png" alt="Gumroad Logo">
          </div>
          <div class="store-title">Gumroad</div>
        </a>
        <!-- Freepik -->
        <a href="https://www.freepik.com/author/erdpme" class="store-item" target="_blank">
          <div class="logo">
            <img src="https://res.cloudinary.com/erdp-me/image/upload/v1659710331/store/freepik_zy1d9i.png" alt="Freepik Logo">
          </div>
          <div class="store-title">Freepik</div>
        </a>
        <!-- Creative Fabrica -->
        <a href="https://www.creativefabrica.com/designer/erdpme/" class="store-item" target="_blank">
          <div class="logo">
            <img :src="cfLogo" alt="Creative Fabrica Logo">
          </div>
          <div class="store-title">Creative Fabrica</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'

const cmLogo = ref('')
const erdpStoreLogo = ref('')
const cfLogo = ref('')

const updateImages = () => {
  // Check the user's color scheme preference
  const colorSchemePreference = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

  // Set the appropriate image paths based on the color scheme preference
  const cmLogoLight = 'https://res.cloudinary.com/erdp-me/image/upload/v1659710331/store/cm_n1jy0t.png'
  const cmLogoDark = 'https://res.cloudinary.com/erdp-me/image/upload/v1689301329/creativemarket_dlxlqp.svg'
  const erdpStoreLogoLight = 'https://res.cloudinary.com/erdp-me/image/upload/v1689302428/Erdp_store_qztqol.svg'
  const erdpStoreLogoDark = 'https://res.cloudinary.com/erdp-me/image/upload/v1689302722/Erdp_store_light_ftsi7b.svg'
  const cfLogoLight = 'https://res.cloudinary.com/erdp-me/image/upload/v1689303012/creative-fabrica-light_xxylrj.png'
  const cfLogoDark = 'https://res.cloudinary.com/erdp-me/image/upload/v1689303011/creative-fabrica-dark_lzxpxp.png'

  cmLogo.value = colorSchemePreference === 'dark' ? cmLogoDark : cmLogoLight
  erdpStoreLogo.value = colorSchemePreference === 'dark' ? erdpStoreLogoDark : erdpStoreLogoLight
  cfLogo.value = colorSchemePreference === 'dark' ? cfLogoDark : cfLogoLight
}

onMounted(() => {
  updateImages()
  // Listen for color scheme preference changes
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateImages)
})

onBeforeUnmount(() => {
  // Clean up the event listener
  window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateImages)
})
</script>

<style lang="scss" scoped>
.store {
  background: var(--background-sec);
  border: 1px solid var(--divider-color);
  padding: rem(50px) 0;
  margin: rem(100px) 0;

  .text {
    text-align: center;

    .title {
      font-weight: 600;
      font-size: rem(25px);
    }

    .description {
      color: var(--font-color-sec);
      font-size: rem(16px);
    }
  }

  .store-list {
    @include flex($jst-c: space-around);
    margin-top: rem(30px);
    flex-wrap: wrap-reverse;

    @include media-breakpoint-down(lg) {
      @include flex(column);
    }

    .store-item {
      @include flex(column, $jst-c: center, $alg-i: center);
      cursor: pointer;
      margin: rem(60px) 0 rem(40px) 0;
      transition: $transition;
      text-decoration: none;

      @include media-breakpoint-down(lg) {
        margin: rem(30px) 0;
      }

      .logo {
        img {
          @include area (auto, rem(30px));
        }
        span {
          font-size: rem(25px);
        }
      }

      .store-title {
        color: var(--font-color-sec);
        font-weight: 700;
        font-size: rem(15px);
        letter-spacing: rem(1px);
        margin-top: rem(10px);
        transition: $transition;
      }

      &:hover {
        .store-title {
          color: var(--font-color);
        }
      }
    }
  }
}
</style>
